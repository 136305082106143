<template>
 
  <div class="">
      
    <!-- plans -->

    <div class="plans">
      <div 
        v-for="(plan, plan_key) in data.plans"
        :class="{plan: true, active: choosed_plan.id == plan.id}"
        :key="plan_key"
        @click="choosePlan(plan)"
        >
        <div class="nm">
          {{ plan.name }}
        </div>

        <div v-if="plan.discount" class="off">
          -{{ plan.discount }}%
        </div>

        <div class="price">
          <div class="vl">
            {{ plan.price }}€
          </div>
          <div class="per">
            {{ plan.months }}/Month
          </div>
        </div>

        <div class="desc">
          {{ plan.desc }}
        </div>

        <div class="btn">
          Choose plan
        </div>

      </div>
    </div>

    <!-- table -->

    <div class="groups">
      <div 
        v-for="(group, group_key) in groups_calculated"
        :class="{group: true}"
        :key="group_key"
        >

        <div class="ttl" v-if="group.options?.length">
          <h2>{{ group.name }}</h2>
          <div class="desc">
            {{ group.desc }}
          </div>
        </div>

        <div class="tbl">

          <div 
            v-for="(option, option_key) in group.options"
            :class="{option: true, active: option.is_checked_manual}"
            :key="group_key"
            >
            <div class="ckb">
              <UiToggle 
                v-model="option.is_checked"
                :disabled="choosed_plan.is_edited && option.is_checked_plan"
                @update:modelValue="toggleChoosedOption(option)"
                />
            </div>
            <div :class="{name: true, clickable: option.ref}">
              {{ option.name }}
              <i v-if="option.ref" @click="scrollTo(option.ref)" class="icon-question"></i>
            </div>

            <div class="period">
              {{ option.period_name }}

              <div 
                v-if="option.is_enabled_plus" 
                class="bplus"
                @click="plusChoosedOption(option)"
                >Add</div>
              <div 
                v-if="option.is_enabled_minus" 
                class="bminus"
                @click="minusChoosedOption(option)"
                >-</div>
                
            </div>

            <div class="price">
              <span class="old">
                {{ option.price_old }}€
              </span>
              <span class="new">
                {{ option.price_new }}€
              </span>
            </div>

          </div>
        </div>

      </div>
    </div>

    <!-- totals -->

    <div class="total">

      <div class="row subtotal">
        <div class="nm">Monhly:</div>
      </div>

      <div class="row subtotal">
        <div class="nm">{{ choosed_plan.name }}</div>
        <div class="vl">{{ totals_calculated.plan }}€</div>
      </div>

      <div v-if="totals_calculated.advanced" class="row advanced">
        <div class="nm">Options</div>
        <div class="vl">{{ totals_calculated.advanced }}€</div>
      </div>

      <div v-if="totals_calculated.discounted" class="row discounted">
        <div class="nm">Discounted</div>
        <div class="vl">-{{ totals_calculated.discounted }}€</div>
      </div>

      <div class="row all">
        <div class="nm">Total</div>
        <div class="vl">{{ totals_calculated.plan + totals_calculated.advanced }}€</div>
      </div>


    </div>
    
 
  </div>

  
</template>


<script setup>

const emits = defineEmits(['change'])
var data = ref(
  {
  "plans":[
    {
      "id": "trial",
      "name": "Trial - 3 months",
      "price": 849, 
      "discount": 0, 
      "months": 3, 
      "desc": "Efficient three-month plan to try out all the main advertising tools.",
      "is_edited": false,
      "options": [
        {"id": "sitewide", "count": 3},        
        {"id": "email_newsletter", "count": 1},
        {"id": "chat_newsletter", "count": 1},
        {"id": "gd_business_trial", "count": 3},
        {"id": "ad_free_trial", "count": 3},                                 
        {"id": "header_main_trial", "count": 1},     
        {"id": "diary_main_trial", "count": 1}     
      ]
    },
    {
      "id": "lite",
      "name": "Lite",
      "price": 599, 
      "discount": 0, 
      "months": 12, 
      "desc": "Ideal for getting started with basic banners and other adverts.",
      "is_edited": true,
      "options": [
        {"id": "sitewide", "count": 12},        
        {"id": "email_newsletter", "count": 3},
        {"id": "chat_newsletter", "count": 3},
        {"id": "gd_business", "count": 1},
        {"id": "ad_free", "count": 1},                                 

        {"id": "header_group_low", "count": 1},   

        {"id": "header_group_mid", "count": 1},   
      ]
    },
    {
      "id": "advanced",
      "name": "Advanced",
      "price": 1149, 
      "discount": 0, 
      "months": 12, 
      "desc": "Great variation of essential adverts suitable for any cannabis company.",
      "is_edited": true,
      "options": [
        {"id": "sitewide", "count": 12},        
        {"id": "email_newsletter", "count": 6},
        {"id": "chat_newsletter", "count": 6},
        {"id": "gd_business", "count": 1},
        {"id": "ad_free", "count": 1},                                       
        {"id": "media_tab", "count": 1},                        
        {"id": "custom_contest", "count": 4},      
        {"id": "article_link", "count": 1},     
        {"id": "banner_design", "count": 1},    

        {"id": "header_strain_low", "count": 1},   
        {"id": "header_group_low", "count": 1},  

        {"id": "header_strain_mid", "count": 1},                           
        {"id": "header_group_mid", "count": 1},
      ]
    },
    {
      "id": "gold",
      "name": "Gold",
      "price": 2499, 
      "discount": 0, 
      "months": 12, 
      "desc": "Recommended choice for running a vast advertising company.",
      "is_edited": true,
      "options": [
        {"id": "sitewide", "count": 12},
        {"id": "email_newsletter", "count": 9},
        {"id": "chat_newsletter", "count": 9},
        {"id": "gd_business", "count": 1},
        {"id": "ad_free", "count": 1},                                       
        {"id": "media_tab", "count": 1},                        
        {"id": "custom_contest", "count": 10},      
        {"id": "article_link", "count": 2},     
        {"id": "banner_design", "count": 2},     
        {"id": "buy_button", "count": 1},    
        {"id": "featured_brand", "count": 1},   
        {"id": "box_banner_recommended", "count": 1},   
 
        {"id": "header_diaries_low", "count": 1},
        {"id": "header_grower_low", "count": 1},
        {"id": "header_breeder_low", "count": 1},
        {"id": "header_strain_low", "count": 1},   
        {"id": "header_group_low", "count": 1}, 
        
        {"id": "header_diaries_mid", "count": 1},
        {"id": "header_grower_mid", "count": 1},
        {"id": "header_breeder_mid", "count": 1},

        {"id": "header_breeder_high", "count": 1},
        {"id": "header_strain_high", "count": 1},                           

      ]
    },
    {
      "id": "platinum",
      "name": "Platinum",
      "price": 3699, 
      "discount": 0, 
      "months": 12, 
      "desc": "Recommended choice for running a vast advertising company.",
      "is_edited": true,
      "options": [
        {"id": "sitewide", "count": 12, "double": true},        
        {"id": "email_newsletter", "count": 12},
        {"id": "chat_newsletter", "count": 12},
        {"id": "gd_business", "count": 1},
        {"id": "ad_free", "count": 1},                                       
        {"id": "media_tab", "count": 1},                        
        {"id": "custom_contest", "count": -1},     
        {"id": "article_link", "count": 3},     
        {"id": "banner_design", "count": 3},     
        {"id": "buy_button", "count": 1},     
        {"id": "featured_brand", "count": 1},  
        {"id": "top_brand", "count": 1},  
        {"id": "box_banner_recommended", "count": 1},    
        
        {"id": "header_diaries_low", "count": 1},
        {"id": "header_grower_low", "count": 1},
        {"id": "header_breeder_low", "count": 1},
        {"id": "header_strain_low", "count": 1},   
        {"id": "header_group_low", "count": 1},    
        
        {"id": "header_diaries_mid", "count": 1},
        {"id": "header_grower_mid", "count": 1},
        {"id": "header_breeder_mid", "count": 1},
        {"id": "header_strain_mid", "count": 1},                           
        {"id": "header_group_mid", "count": 1},     

        {"id": "header_diaries_high", "count": 1},
        {"id": "header_grower_high", "count": 1},
        {"id": "header_breeder_high", "count": 1},
        {"id": "header_strain_high", "count": 1},                           
        {"id": "header_group_high", "count": 1},      
      ]
    }
  ],

  "groups":[
    {
      "id": "plan",
      "name": "Plan Options",
      "desc": "",
      "options":[
        "sitewide",
        "email_newsletter",
        "chat_newsletter",
        "gd_business",
        "ad_free",
        "media_tab",
        "custom_contest", 
        "article_link",
        "banner_design",
        "buy_button",
        "featured_brand",
        "top_brand",
        "box_banner_recommended",

        "gd_business_trial",
        "ad_free_trial",
        "header_main_trial",
        "diary_main_trial",
      ]
    },
    {
      "id": "low",
      "name": "Low season options",
      "desc": "January / July / August / September / October",
      "options":[        
        "header_main_low",
        "header_question_low",
        "header_grower_low",
        "header_group_low",
        "header_journal_low",
        "header_strain_low",
        "header_diaries_low",
        "header_breeder_low",
        "header_nutrient_low",
        "header_tent_low",
        "header_light_low",
      ]
    },
    {
      "id": "mid",
      "name": "Mid season options",
      "desc": "February / June / November / December",
      "options":[ 
        "header_main_mid",
        "header_question_mid",
        "header_grower_mid",
        "header_group_mid",
        "header_journal_mid",
        "header_strain_mid",
        "header_diaries_mid",
        "header_breeder_mid",
        "header_nutrient_mid",
        "header_tent_mid",
        "header_light_mid",
      ]
    },
    {
      "id": "high",
      "name": "High season options",
      "desc": "March / April / May",            
      "options":[ 
        "header_main_high",
        "header_question_high",
        "header_grower_high",
        "header_group_high",
        "header_journal_high",
        "header_strain_high",
        "header_diaries_high",
        "header_breeder_high",
        "header_nutrient_high",
        "header_tent_high",
        "header_light_high",
      ]
    }
    
  ],

  "options":[

    {
      "id": "header_main_trial",
      "ref": "header_main",
      "name": "Header banner - main page",
      "max": 1,
      "period": "day",
      "price": 30,
      "supported_plan": [
        "trial"
      ]
    },

    {
      "id": "diary_main_trial",
      "ref": "diary_main",
      "name": "Header banner - diary page",
      "max": 1,
      "period": "day",
      "price": 30,
      "supported_plan": [
        "trial"
      ]
    },

    {
      "id": "gd_business_trial",
      "ref": "gd_business",
      "name": "GD Business",
      "max": 3,
      "period": "months",
      "price": 1500,
      "supported_plan": [
        "trial"
      ]
    },

    {
      "id": "ad_free_trial",
      "ref": "ad_free",
      "name": "Brand Page Ad Free",
      "max": 3,
      "period": "months",
      "price": 0,
      "supported_plan": [
        "trial", 
      ]
    },

    {
      "id": "sitewide",
      "ref": "sitewide",
      "name": "Sitewide banners",
      "max": 12,
      "period": "months",
      "price": 399,
      "supported_plan": [
        "trial",
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },

    {
      "id": "email_newsletter",
      "ref": "email_newsletter",
      "name": "Email Newsletter",
      "max": 12,
      "period": "times",
      "price": 200,
      "supported_plan": [
        "trial",
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },

    {
      "id": "chat_newsletter",
      "ref": "",
      "name": "Chat Newsletter",
      "max": 12,
      "period": "times",
      "price": 200,
      "supported_plan": [
        "trial",
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },

    {
      "id": "gd_business",
      "ref": "gd_business",
      "name": "GD Business",
      "max": 1,
      "period": "year",
      "price": 1500,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },

    {
      "id": "ad_free",
      "ref": "ad_free",
      "name": "Brand Page Ad Free",
      "max": 1,
      "period": "year",
      "price": 0,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },

    {
      "id": "media_tab",
      "ref": "media_tab",
      "name": "Brand Page Photos & Videos Tab",
      "max": 1,
      "period": "year",
      "price": 1200,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },

    {
      "id": "custom_contest",
      "ref": "custom_contest",
      "name": "Custom contest + Sidebar banner",
      "max": 12,
      "period": "months",
      "price": 400,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    }, 

    {
      "id": "article_link",
      "ref": "article_link",
      "name": "Article + SEO link",
      "max": 2,
      "period": "half-year",      
      "price": 800,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },

    {
      "id": "banner_design",
      "ref": "",
      "name": "Banner Designs",
      "max": 1,
      "period": "set",      
      "price": 150,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    }, 

    {
      "id": "buy_button",
      "ref": "buy_button",
      "name": "Buy Button",
      "max": 1,
      "period": "year",
      "price": 1000,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },

    {
      "id": "top_brand",
      "ref": "top_brand",
      "name": "Top brand on Breeders page",
      "max": 1,
      "period": "year",
      "price": 700,
      "supported_plan": [
        "platinum",
        "platinum_plus"
      ]
    },

    {
      "id": "featured_brand",
      "ref": "featured_brand",
      "name": "Featured Brand",
      "max": 1,
      "period": "year",
      "price": 1000,
      "supported_plan": [
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },

    {
      "id": "box_banner_recommended",
      "ref": "box_banner_recommended",
      "name": "Box banner on main page in the recommended",
      "max": 1,
      "period": "year",
      "price": 1000,
      "supported_plan": [
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },

    {
      "id": "header_main_low",
      "ref": "header_main",
      "name": "Header banner - main page",
      "max": 5,
      "period": "months",
      "price": 1200,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },

    {
      "id": "header_main_mid",
      "ref": "header_main",
      "name": "Header banner - main page",
      "max": 4,
      "period": "months",
      "price": 2500,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },

    {
      "id": "header_main_high",
      "ref": "header_main",
      "name": "Header banner - main page",
      "max": 3,
      "period": "months",
      "price": 3500,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    }, 

    {
      "id": "header_question_low",
      "ref": "header_main",
      "name": "Header banner - questions",
      "max": 5,
      "period": "months",
      "price": 500,
      "supported_plan": [
        // "lite",
        // "advanced",
        // "gold",
        // "platinum",
        // "platinum_plus"
      ]
    },

    {
      "id": "header_question_mid",
      "ref": "header_main",
      "name": "Header banner - questions",
      "max": 4,
      "period": "months",
      "price": 1000,
      "supported_plan": [
        // "lite",
        // "advanced",
        // "gold",
        // "platinum",
        // "platinum_plus"
      ]
    },

    {
      "id": "header_question_high",
      "ref": "header_main",
      "name": "Header banner - questions",
      "max": 3,
      "period": "months",
      "price": 1500,
      "supported_plan": [
        // "lite",
        // "advanced",
        // "gold",
        // "platinum",
        // "platinum_plus"
      ]
    }, 
 

    {
      "id": "header_diaries_low",
      "ref": "header_main",
      "name": "Header banner - diaries",
      "max": 5,
      "period": "months",
      "price": 1800,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      "id": "header_diaries_mid",
      "ref": "header_main",
      "name": "Header banner - diaries",
      "max": 4,
      "period": "months",
      "price": 3600,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      "id": "header_diaries_high",
      "ref": "header_main",
      "name": "Header banner - diaries",
      "max": 3,
      "period": "months",
      "price": 5000,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    }, 
 

    {
      "id": "header_grower_low",
      "ref": "header_main",
      "name": "Header banner - growers",
      "max": 5,
      "period": "months",
      "price": 900,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      "id": "header_grower_mid",
      "ref": "header_main",
      "name": "Header banner - growers",
      "max": 4,
      "period": "months",
      "price": 1800,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      "id": "header_grower_high",
      "ref": "header_main",
      "name": "Header banner - growers",
      "max": 3,
      "period": "months",
      "price": 2500,
      "supported_plan": [
        "lite",
        "advanced",
        "platinum",
        "platinum_plus"
      ]
    }, 


    {
      "id": "header_group_low",
      "ref": "header_main",
      "name": "Header banner - 5 pages: questions, journal, nutrients, tents, lights",
      "max": 5,
      "period": "months",
      "price": 500,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      "id": "header_group_mid",
      "ref": "header_main",
      "name": "Header banner - 5 pages: questions, journal, nutrients, tents, lights",
      "max": 4,
      "period": "months",
      "price": 700,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      "id": "header_group_high",
      "ref": "header_main",
      "name": "Header banner - 5 pages: questions, journal, nutrients, tents, lights",
      "max": 3,
      "period": "months",
      "price": 1500,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    }, 


    {
      "id": "header_journal_low",
      "ref": "header_main",
      "name": "Header banner - journal",
      "max": 5,
      "period": "months",
      "price": 300,
      "supported_plan": [
        // "lite",
        // "advanced",
        // "gold",
        // "platinum",
        // "platinum_plus"
      ]
    },
    {
      "id": "header_journal_mid",
      "ref": "header_main",
      "name": "Header banner - journal",
      "max": 4,
      "period": "months",
      "price": 700,
      "supported_plan": [
        // "lite",
        // "advanced",
        // "gold",
        // "platinum",
        // "platinum_plus"
      ]
    },
    {
      "id": "header_journal_high",
      "ref": "header_main",
      "name": "Header banner - journal",
      "max": 3,
      "period": "months",
      "price": 1000,
      "supported_plan": [
        // "lite",
        // "advanced",
        // "gold",
        // "platinum",
        // "platinum_plus"
      ]
    }, 


    {
      "id": "header_strain_low",
      "ref": "header_main",
      "name": "Header banner - strains",
      "max": 5,
      "period": "months",
      "price": 1200,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      "id": "header_strain_mid",
      "ref": "header_main",
      "name": "Header banner - strains",
      "max": 4,
      "period": "months",
      "price": 2500,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      "id": "header_strain_high",
      "ref": "header_main",
      "name": "Header banner - strains",
      "max": 3,
      "period": "months",
      "price": 3500,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    }, 


    {
      "id": "header_breeder_low",
      "ref": "header_main",
      "name": "Header banner - category Breeder",
      "max": 5,
      "period": "months",
      "price": 900,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      "id": "header_breeder_mid",
      "ref": "header_main",
      "name": "Header banner - category Breeder",
      "max": 4,
      "period": "months",
      "price": 1800,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      "id": "header_breeder_high",
      "ref": "header_main",
      "name": "Header banner - category Breeder",
      "max": 3,
      "period": "months",
      "price": 2500,
      "supported_plan": [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },


    {
      "id": "header_nutrient_low",
      "ref": "header_main",
      "name": "Header banner - category Nutrient",
      "max": 5,
      "period": "months",
      "price": 900,
      "supported_plan": [
        // "lite",
        // "advanced",
        // "gold",
        // "platinum",
        // "platinum_plus"
      ]
    },
    {
      "id": "header_nutrient_mid",
      "ref": "header_main",
      "name": "Header banner - category Nutrient",
      "max": 4,
      "period": "months",
      "price": 1800,
      "supported_plan": [
        // "lite",
        // "advanced",
        // "gold",
        // "platinum",
        // "platinum_plus"
      ]
    },
    {
      "id": "header_nutrient_high",
      "ref": "header_main",
      "name": "Header banner - category Nutrient",
      "max": 3,
      "period": "months",
      "price": 2500,
      "supported_plan": [
        // "lite",
        // "advanced",
        // "gold",
        // "platinum",
        // "platinum_plus"
      ]
    },


    {
      "id": "header_tent_low",
      "ref": "header_main",
      "name": "Header banner - category Tent",
      "max": 5,
      "period": "months",
      "price": 350,
      "supported_plan": [
        // "lite",
        // "advanced",
        // "gold",
        // "platinum",
        // "platinum_plus"
      ]
    },
    {
      "id": "header_tent_mid",
      "ref": "header_main",
      "name": "Header banner - category Tent",
      "max": 4,
      "period": "months",
      "price": 700,
      "supported_plan": [
        // "lite",
        // "advanced",
        // "gold",
        // "platinum",
        // "platinum_plus"
      ]
    },
    {
      "id": "header_tent_high",
      "ref": "header_main",
      "name": "Header banner - category Tent",
      "max": 3,
      "period": "months",
      "price": 1500,
      "supported_plan": [
        // "lite",
        // "advanced",
        // "gold",
        // "platinum",
        // "platinum_plus"
      ]
    },


    {
      "id": "header_light_low",
      "ref": "header_main",
      "name": "Header banner - category Lights",
      "max": 5,
      "period": "months",
      "price": 350,
      "supported_plan": [
        // "lite",
        // "advanced",
        // "gold",
        // "platinum",
        // "platinum_plus"
      ]
    },
    {
      "id": "header_light_mid",
      "ref": "header_main",
      "name": "Header banner - category Lights",
      "max": 4,
      "period": "months",
      "price": 700,
      "supported_plan": [
        // "lite",
        // "advanced",
        // "gold",
        // "platinum",
        // "platinum_plus"
      ]
    },
    {
      "id": "header_light_high",
      "ref": "header_main",
      "name": "Header banner - category Lights",
      "max": 3,
      "period": "months",
      "price": 1500,
      "supported_plan": [
        // "lite",
        // "advanced",
        // "gold",
        // "platinum",
        // "platinum_plus"
      ]
    },
  ],
  }
);
var choosed_plan = ref(data.value.plans[4])
var choosed_options = ref({})

const choosePlan = function (plan){
  choosed_plan.value = plan

  changeEvent();
}

const getOptionById = function (id){
  return data.value.options.find(option => option.id == id)
}
const getPlanOptionById = function (id){
  return choosed_plan.value.options.find(o => o.id == id);
}

const deleteChoosedOption = function (option){
  delete choosed_options.value[option.id];      
  changeEvent();
}

const minusChoosedOption = function (option){  
  if(typeof choosed_options.value[option.id] == 'undefined')
    false;
  else if(choosed_options.value[option.id].count <= 1)
    delete choosed_options.value[option.id];      
  else 
    choosed_options.value[option.id].count--;
  
  changeEvent();
}

const toggleChoosedOption = function (option){
  if(choosed_options.value[option.id]){
    deleteChoosedOption(option);
  }else{
    plusChoosedOption(option);
  }
  changeEvent();
}

const plusChoosedOption = function (option){
  if(typeof choosed_options.value[option.id] == 'undefined')
    choosed_options.value[option.id] = {
      count: 1
    };
  else
  choosed_options.value[option.id].count++;
}
const getDiscountedPrice = function(discount, price){
    return price - price*(discount)/100;
}
const changeEvent = function(){
  emits('change', checkout_order.value)
}
// 

const scrollTo = function(ref){
  var el = document.querySelector('*[refc="' + ref + '"]')
  if(el){
    el.scrollIntoView({ block: 'start' });
    window.scrollBy(0, -60);
  }
  
}

const groups_calculated = computed(() => {
 
  var calc = [];
  for(var group of data.value.groups){

    // options
    var options = [];
    for(var option of group.options){
      var opt = getOptionById(option);
      var opt_plan = getPlanOptionById(option);
      var opt_choosed = choosed_options.value[opt.id];
      var total_count = (opt_plan ? opt_plan.count : 0) + (choosed_options.value[opt.id] ? choosed_options.value[opt.id].count : 0);
      total_count = (opt_plan && opt_plan.count < 0 ? opt.max : total_count );

      if(opt.supported_plan.indexOf(choosed_plan.value.id) == -1)
        continue;


      if(opt_choosed) 
        opt.period_name = ((opt_plan?.count ? opt_plan.count : 0) + opt_choosed.count) + ' ' + opt.period;
      else if(opt_plan?.count == -1)
        opt.period_name = opt_plan ? 'Unlimited': '';
      else if(opt_plan?.count == -2) 
        opt.period_name = opt_plan ? 'Forever': '';
      else if(opt_plan?.count) 
        opt.period_name = opt_plan.count + ' ' + opt.period;
      else 
        opt.period_name = 1 + ' ' + opt.period;


      
      if(opt.max > total_count && choosed_plan.value.is_edited){
        opt.is_enabled_plus = true;
      }else{
        opt.is_enabled_plus = false;
      }

      if(opt_choosed){
        opt.is_enabled_minus = true;
      }else{
        opt.is_enabled_minus = false;
      }
      


      // price

      if(opt_choosed){
        opt.price_old = opt.price*opt_choosed.count;
        opt.price_new = getDiscountedPrice(choosed_plan.value.discount, opt.price*opt_choosed.count);
      }else{
        opt.price_old = opt.price;
        opt.price_new = getDiscountedPrice(choosed_plan.value.discount, opt.price);
      }
  

      opt.is_checked = opt_plan || opt_choosed;
      opt.is_checked_plan = opt_plan;
      opt.is_checked_manual = opt_choosed;


      // save option
      options.push(opt)
    }




    // save
    calc.push({
      id: group.id,
      name: group.name,
      desc: group.desc,
      options: options
    })
  }

  return calc;
});



const totals_calculated = computed(() => {

  var calc_totals = {
    plan: choosed_plan.value.price,      
    discounted: 0,
    advanced: 0     
  };

  if(choosed_plan.value.discount && choosed_plan.value.discount > 0){
    calc_totals.discounted += Math.round(100*choosed_plan.value.price/(100-choosed_plan.value.discount) - choosed_plan.value.price);
  }

  for(var i in choosed_options.value){
    var option = getOptionById(i);
    var price = option.price * choosed_options.value[i].count;    
    calc_totals.advanced += getDiscountedPrice(choosed_plan.value.discount, price);
    calc_totals.discounted += price - getDiscountedPrice(choosed_plan.value.discount, price);
  }

  return calc_totals;

});


const checkout_order = computed(() => {
  
  var order = [];
 
  order.push({
    name: choosed_plan.value.name,
    count: 1,
    price: choosed_plan.value.price,      
    total: choosed_plan.value.price,      
  });

  for(var i in choosed_options.value){
    var option = getOptionById(i);
    var price = option.price * choosed_options.value[i].count;    
    
    order.push({
      name: option.name + ' (' + option.id + ')',
      count: choosed_options.value[i].count,
      price: option.price,
      discount: price - getDiscountedPrice(choosed_plan.value.discount, price),
      total: getDiscountedPrice(choosed_plan.value.discount, price),
    });
    
  }
  
  return order;
})

changeEvent();

</script>
 


<style scoped>


/* PLANS */

.plans{
display: flex;
/* align-items: center; */
max-width: 1100px;
margin: 0 auto;
overflow-x: auto;
}
.plans .plan{
width: 25%;
display: flex;
flex-wrap: wrap;
position: relative;
margin: 0 1rem;
padding: 1.5rem;
border-radius: 20px;
background-color: var(--un-background-color-gray);
min-width: 180px;
cursor: pointer;
}
.plans .plan .nm{
width: 100%;
font-weight: bold;
font-size: 1rem;
}
.plans .plan .off{
position: absolute;
top: 2rem;
right: 2rem;
background-color: orange;
color: white;
border-radius: 20px;
padding: 0px 10px;
font-weight: bold;
font-size: 0.8rem;
    margin-top: -7px;
}
.plans .plan .price{
width: 100%;
display: flex;
align-items: center;
margin-top: 10px;
}
.plans .plan .desc{
margin-bottom: auto;
margin-top: 1rem;
/* height: 70px; */
}
.plans .plan .price .vl{
font-size: 1.6rem;
font-weight: bold;
}
.plans .plan .price .per{
margin-left: 10px;
color: #a2a2a2;
}
.plans .plan .btn{
border: 2px #2a8858 solid;
border-radius: 10px;
color: #2a8858;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
padding: 10px;
font-weight: bold;
cursor:pointer;
margin-top: 1rem;
}

.plans .plan:hover{
  background-color: var(--un-background-color-gray-light);
}
/* PLANS ACTIVE */

.plans .plan.active{
  background-color: #2a8858;
  color: white;
}

.plans .plan.active .per{
color:#ffffff8c;
}
.plans .plan.active .btn{
background-color:white;
}

@media (max-width: 768px) {
.plans{
  overflow-x: auto;
}
.plans .plan{
  min-width: 180px;
  /* background-color: whitesmoke; */
}
.plans .plan .desc{
overflow-y: hidden;
/* height: 80px; */
}
.plans .plan .off{
top: 20px;
right: 12px;
padding: 2px 5px;
}
}

/* GROUPS */

.groups{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  padding: 2.5rem;
}
.groups .group{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.groups .group .ttl{
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
}
.groups .group .ttl h2{
margin-bottom: 0;
}
.groups .group .ttl .desc{
  margin-left: 1rem;
  color: gray;
}
.groups .group .tbl{
  display: flex;
  flex-direction: column;
}
.groups .group .tbl .option{
  display: flex;
  align-items: center;
  width: 100%;
  
  padding: 8px 0;
}
.groups .group .tbl .option:not(:last-child){
border-bottom: 1px var(--un-element-color-gray-dark) solid; 
}
.groups .group .tbl .option .ckb{
  width: 70px;
  flex-shrink: 0;
}
.groups .group .tbl .option .name{
  width: 100%; 
}
.groups .group .tbl .option .name.clickable{   
  cursor: pointer;
}
.groups .group .tbl .option .name .icon{
margin-left: 4px;
display: none;
}

.groups .group .tbl .option .name.clickable .icon{   
display: inline-block;
}
.groups .group .tbl .option .period{
  width: 200px;
  flex-shrink: 0;
  position: relative;
}
.groups .group .tbl .option .price{
  width: 100px;
  flex-shrink: 0;
  opacity:0.3;
}
.groups .group .tbl .option .price .new{
  color: #67ad3c;
  font-weight: bold;
}
.groups .group .tbl .option .price .old{
  text-decoration: line-through;
  margin-right: 5px;
}
.groups .group .tbl .option.active .price{
opacity:1;
}

.groups .group .tbl .option .bplus{
display: inline-block;
background-color: var(--un-background-color-gray-light);
border-radius: 20px;
padding: 2px 6px;
font-size: 0.7rem;
margin-left: 1rem;
cursor: pointer;
user-select: none;
    margin-bottom: -1px;
    margin-top: -1px;
}
.groups .group .tbl .option .bminus{
  display: inline-block;
    background-color: var(--un-background-color-gray-light);
    border-radius: 20px;
    padding: 0px 6px 2px 6px;
    font-size: 1rem;
    height: 20px;
    margin-left: 0.3rem;
    min-width: 20px;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    position: absolute;
    left: -32px;
    line-height: 1rem;
}

.groups .group .tbl .option .bminus:hover,
.groups .group .tbl .option .bplus:hover{
background-color: #2a8858;
color: white;
}

.groups .ui.toggle.checkbox input:checked ~ .box:before, .ui.toggle.checkbox input:checked ~ label:before{

background-color: #2a8858 !important;
}
@media (max-width: 768px) {
.groups{
  padding: 0 0;
  margin-top:2rem;

}

.groups .group .tbl .option{
  flex-wrap: wrap;
}
.groups .group .tbl .option .name{
  width: calc(100% - 48px);
  font-weight:bold;
}

.groups .group .tbl .option .period{
  margin-left: 48px;
  margin-right: auto;
  margin-top: 5px;
}
.groups .group .tbl .option .bplus{
margin-left: 8px;
}
.groups .group .tbl .option .price{
  margin-left: 48px;
  margin-top: 5px;
}
.groups .group .tbl .option .ckb{
width: 60px;
zoom:0.8;
margin-top: 9px;
}
}


/* TOTAL */

.total{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 900px;
  margin: 0 auto;
  padding: 2.5rem;
}
.total .row{
  display: flex;
  min-width: 260px;
}
.total .row .nm{
width:100%;
}

.total .row .vl{
width:100px;
flex-shrink:0;
}
.total .row.all{
font-weight:bold;
font-size: 1.4rem;
margin-top: 0.5rem;
}


.total .row.discounted{
color: #67ad3c;
font-weight: bold;
}
/*  */

</style>